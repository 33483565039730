<template>
  <section id="dashboard-ecommerce">
    <div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Estadísticas
          </h4>
        </div>
        <div class="card-body">
          Aquí se vera el dashboard
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'DashboardView',
  components: {},
  data() {
    return {
      loading: false,
    }
  },
  created() {

  },
}
</script>

<style scoped>

</style>
